// TempData
import TempDataNavBar from '../TempData_NavBar';
import TempDataMegaMenu from '../TempData_MegaMenu';
import TempDataFooter from '../TempData_footer';
import * as ColorGuide from '../../../cssGuides/colorGuide';

// Page Data
const Data = {
  pageName: 'Collapsible Test PAge',
  pageTemplate: 'Template3',
  pageData: {
    nav: TempDataNavBar,
    megaMenu: TempDataMegaMenu,
    footer: TempDataFooter,
    level_1_Id: 'Page_1',
    pageTitle: 'HTML render test page',
    pageDescription: 'Tester component - HTML render',
    bannerImage: null, // accepts null
    bannerContent: '<h1>Banner text accepts HTML</h1>', // accepts null
    bottomContent: [],
    desktopTools: {}, // accepts null
  },
  pageContent: [
    {
      componentName: 'BlockArea',
      componentData: {
        backgroundColor: ColorGuide.aqua,
      },
      componentChildren: [
        {
          componentName: 'Columns',
          componentData: {
            columns: 3,
            gap: '40',
            minWidth: '1024px',
          },
          componentChildren: [
            {
              componentName: 'Collapsible',
              componentData: {
                id: 'second',
                titleOn: 'Færre livsændringer',
                titleOff: 'Flere livsændringer',
              },
              componentChildren: [
                {
                  componentName: 'HtmlRender',
                  componentData: {
                    html:
                      'linie 001<br/>'
                      + 'linie 002<br/>'
                      + 'linie 003<br/>'
                      + 'linie 004<br/>'
                      + 'linie 005<br/>'
                      + 'linie 006<br/>'
                      + 'linie 007<br/>'
                      + 'linie 008<br/>'
                      + 'linie 009<br/>'
                      + 'linie 010<br/>',
                  },
                },
              ],
            },
            {
              componentName: 'Collapsible',
              componentData: {
                id: 'first',
                titleOn: 'Færre livsændringer',
                titleOff: 'Flere livsændringer',
              },
              componentChildren: [
                {
                  componentName: 'HtmlRender',
                  componentData: {
                    html:
                      '<h2 class="text-center">Er der sket ændringer i dit liv?</h2><p class="text-center">Vi guider dig igennem store og små begivenheder, som kan påvirke din pension</p>',
                  },
                },
                {
                  componentName: 'Collapsible',
                  componentData: {
                    id: 'firsttry',
                    titleOn: 'Færre livsændringer',
                    titleOff: 'Flere livsændringer',
                  },
                  componentChildren: [
                    {
                      componentName: 'HtmlRender',
                      componentData: {
                        html:
                          '<h2 class="text-center">Er der sket ændringer i dit liv?</h2><p class="text-center">Vi guider dig igennem store og små begivenheder, som kan påvirke din pension</p>',
                      },
                    },
                  ],
                },
              ],
            },

          ],
        },
      ],
    },
  ],
};

export default Data;
